<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';

const route = useRoute();
const bannersViewController = useBannersViewController();
const metadataStore = useMetadataStore();
const { banners } = storeToRefs(metadataStore);

function handleBannerPressed() {
  if (route.name === RouteName.WELCOME) {
    bannersViewController.startOrder();
  }
}
</script>

<template>
  <button
    @click="handleBannerPressed()"
    type="button"
    class="relative full-hd"
  >
    <div
      v-if="banners.length > 0"
      class="absolute top-0 z-0"
      :class="{
        'blur-sm brightness-75': route.name === RouteName.FULFILLMENT,
      }"
    >
      <ImageCarousel
        :data-images="banners"
        tailwind-classes="full-hd"
      />
    </div>

    <RouterView />
  </button>
</template>
