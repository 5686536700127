export const en = {
  FROM: 'From',
  SCAN_QR: 'Scan QR',
  CLAIM_COUPON: 'Redeem coupon',
  COUPON_QR_DESCRIPTION: 'Draw the QR code to the green light of our kiosk.',
  COUPON_SUCCESS: 'Coupon validated successfully!',
  VALIDATE_QR: 'Validate',
  WRITE_COUPON_CODE: 'Write your coupon code here',
  TABLE_NUMBER: 'Enter the device number here',
  TABLE_HINT: 'Scan the qr or insert device number',
  TABLE: 'Table',
  NEXT: 'Next',
  BACK: 'Go back',
  OPTIONAL: 'Optional',
  DONE: 'Done',
  SELECT_LANGUAGE: 'Select language',
  SELECT_ORDER_TYPE: 'Where do you want to eat?',
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again or late',
  INTERNAL_ERROR: 'Internal error',
  EAT_HERE: 'Eat here',
  RESTART: 'Restart',
  RETRY: 'Retry',
  CONTINUE: 'Continue',
  ACCEPT: 'Accept',
  EDIT: 'Edit',
  DELETE: 'Delete',
  TAKE_AWAY: 'Take away',
  PICK_UP_COUNTER: 'Pick up at counter',
  CRAVING_SELECTION: 'What are you craving?',
  TOTAL: 'Total',
  TAXES: 'Taxes',
  DISCOUNTS: 'Discounts',
  SUBTOTAL: 'Subtotal',
  ADD: 'Add',
  NOT_AVAILABLE: 'Not available',
  ADD_CART: 'Add to cart',
  SAVE: 'Save',
  CUSTOMIZE: 'Customize',
  QUANTITY: 'Quantity',
  PRICE: 'Price',
  ITEMS: 'Items',
  SKIP: 'Skip',
  TYPE_HERE: 'Type Here',
  ADD_VOUCHER: 'Add voucher',
  SCAN_VOUCHER: 'Scan the QR code',
  ADD_ANOTHER_VOUCHER: 'Add another voucher',
  GO_BACK: 'Back',
  VOUCHER_REJECTED: 'Voucher rejected',
  VOUCHER_REJECTED_DETAIL: 'Sorry, there was an error scanning the QR code. Please try again',
  CASH: 'Cash',
  CARD: 'Card',
  PAY_ON_SITE: 'Pay on site',
  MAIN_MENU: 'Main Menu',
  MODIFIERS_BACK_TITLE: 'Would you like to return to the Catalog?',
  MODIFIERS_BACK_DESCRIPTION: 'You will lose the advancement in the customization of this product',
  CANCEL: 'Cancel',
  CONTAINS_ALCOHOL: 'Contains alcohol',
  TIMELINE: {
    CHOOSE_PRODUCT: 'Choose product',
    CUSTOMIZATION: 'Customization',
    CONFIRM: 'Confirm',
    PAY: 'Pay',
  },
  ORDER: {
    BACK_MENU: 'Back to menu',
    BACK_CART: 'Back to cart',
    UPGRADE_MODIFIER_QUESTION: 'Do you want to upgrade your combo?',
    ACCEPT_UPGRADE: 'Yes',
    DENY_UPGRADE: 'No',
    UPGRADE_MODIFIER_DESC: 'You will have the biggest and delicious products',
    UPGRADE_LABEL: 'Upgraded combo',
  },
  CANCEL_ORDER: {
    QUESTION: 'Do you want to cancel the order?',
    ADVICE: 'The progress you have up to this point will be lost and you will return to the beginning',
    CONFIRMATION: 'Yes, cancel',
  },
  DELETE_ITEM: {
    QUESTION: 'Do you want to delete the item?',
    ADVICE: 'You are going to delete the product',
    CONFIRMATION: 'Yes, delete',
  },
  INACTIVITY: {
    TITLE: 'Are you still there?',
    ADVICE: 'In a few seconds the order will be restored and you will return to the beginning',
    QUESTION: 'Do you want to continue with your order?',
  },
  MODIFIERS_MODAL: {
    TITLE: 'Customize the ingredients',
  },
  CHECKOUT: {
    CLIENT_INFO_TITLE: 'Enter your name, so we will call you when your order is ready',
    SUMMARY_TITLE: 'Review your purchase summary and make payment',
    SUMMARY_PAYMENT_MESSAGE: 'Approach or slide the card through the terminal to finalize your purchase',
    GET_CLOSE_CARD: 'Bring the card closer to the device',
    REJECTED_PAY: 'Payment was rejected',
    REJECTED_PAY_AGAIN: 'Payment was rejected again',
    REJECTED_DESCRIPTION: 'We are sorry for what happened, please <b>go to the checkout</b> and pay for your order from there',
    CASH_PAY: 'Pay by cash',
    PROCESSING: 'Processing your order',
    CANCEL: 'Cancel order',
    TABLE: 'Choose how you want to receive your order',
    YOU_CAN_ALSO: 'You can also',
  },
  SUCCESS_ORDER: {
    TITLE: 'Order successfully created',
    DESCRIPTION: 'Please <b>go to the Kiosk cashier</b> and pay for your order there',
    ORDER: 'Order number',
    CARD_DESCRIPTION: 'your payment was successful, <b>enjoy your order</b>',
    POS_FAIL: 'Please <b>come to the cashier</b> with the invoice to verify your order',
  },

  SUCCESS_ORDER_CARD: {
    TITLE: 'Payment successfully made',
    DESCRIPTION: 'Thank you for your purchase, your order will be ready in a few minutes',
    VOUCHER: 'Voucher successfully redeemed',
  },
  USER_INFO: {
    NAME: 'Name',
    NAME_NIT: 'Name / Business name',
    COMPANY_NAME: 'Business name',
    EMAIL: 'Email',
    DOCUMENT_ID: 'ID Number',
    PHONE: 'Mobile number',
    PHONE_PLACEHOLDER: '3101234567',
    EMAIL_PLACEHOLDER: "email{'@'}example.com",
    NAME_PLACEHOLDER: 'Name Last name',
    DOCUMENT_PLACEHOLDER: 'No check digit',
    VALIDATIONS: {
      REQUIRED: 'Field {field} is required',
      EMAIL: 'Email format is invalid',
      ALPHA: 'Only letters are allowed',
      BILLING_NEEDED: 'Data required to issue electronic invoices',
      BILLING_ID_NEEDED: 'Data required to issue electronic invoices and accumulate loyalty points',
      BILLING_NAMES_NEEDED: 'Name and Last name, or Business name are required to issue electronic invoices',
      MIN_LENGTH: 'Minimum size to {field} field is {length}',
      MAX_LENGTH: 'Maximum size to {field} field is {length}',
    },
    CLIENT_TYPE: {
      REGULAR: 'Natural person',
      COMPANY: 'Legal person',
    },
  },
  ALCOHOL: {
    TITLE: 'Caution',
    GALLERY_MESSAGE: 'This product contains alcohol.\nPlease confirm that you are of legal age before proceeding with the purchase',
    MODIFIERS_MESSAGE: 'Some options for this product contain alcohol.\nPlease confirm that you are of legal age before choosing your options',
    CONFIRM: 'I am 18+',
    CANCEL: 'I am not',
  },
  SPACE: 'space',
  ORDER_HERE: 'Order here!',
  PRODUCTS: {
    OUT_OF_STOCK: 'Out of stock',
  },
  LEGAL: {
    TERMS_AND_CONDITIONS: 'I agree to the Terms and Conditions',
    PRIVACY_POLICY: 'I agree to the privacy policy',
  },
  CURRENCY: {
    MODAL_TITLE: 'How would you like to pay?',
  },
};

export default en;
