<script lang="ts" setup>
const route = useRoute();
const metadataStore = useMetadataStore();
const inactiveStoreV2 = useInactiveStoreV2();
const cartUtils = useCartUtils();
const { triggerCancelIdleModal } = useGTMEventsComposableV2();

onBeforeMount(() => {
  // Start countdown
  if (!metadataStore.inactivity) return;

  inactiveStoreV2.setup({
    displayModalSeconds: metadataStore.inactivity.screen,
    inactivitySeconds: metadataStore.inactivity.timer,
  });
});

onBeforeUnmount(() => {
  inactiveStoreV2.pauseChecking();
});

function pressed() {
  inactiveStoreV2.resetInactivity();
}

function cancelInactivityModal() {
  inactiveStoreV2.resetInactivity();
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}

</script>

<template>
  <div
    @mousedown="pressed"
    @touchstart="pressed"
    @touchend="pressed"
    @touchcancel="pressed"
  >
    <slot />
  </div>
  <Teleport to="#inactive-modal">
    <OrderInactivityModal
      v-if="inactiveStoreV2.displayModal"
      :time="inactiveStoreV2.modalCounter"
      @inactive="cartUtils.restartCart(true)"
      @close="cancelInactivityModal()"
    />
  </Teleport>
</template>
