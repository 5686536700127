<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';

const menuStore = useMenuStore();
const settingsStore = useSettingsStore();
const metadataStore = useMetadataStore();
const languageStore = useLanguageStore();
const maintenanceStore = useMaintenanceStore();
const standbyBannerStore = useStandbyBannerStore();
const bannersViewController = useBannersViewController();

const { gtmTransactionId } = storeToRefs(settingsStore);
const { isLanguageEditable, languages } = storeToRefs(languageStore);

const showLoader = computed(() => !metadataStore.kioskSettings || !metadataStore.kioskSettings);

/**
 * TODO: Migrate this logic into composable
 */
async function beforeMount() {
  menuStore.reset();
  // Get data again
  gtmTransactionId.value = '';
  await settingsStore.refreshMetadata();

  if (settingsStore.multibrandData?.multibrand) {
    bannersViewController.startOrder();
  }
}

// Set language with multi brand value
watch(() => languageStore.languages, (newData) => {
  if (newData && settingsStore.multibrandData) {
    const { language } = settingsStore.multibrandData;
    languageStore.setLanguage(language);
  }
});

onBeforeMount(() => beforeMount());

onBeforeRouteLeave((currentRoute) => {
  // Just preserve the checking of its close in standby banner and welcome view
  if (currentRoute.name !== RouteName.STAND_BY_BANNER) {
    standbyBannerStore.stopChecking();
  }
});
</script>

<template>
  <KioskLoading v-if="showLoader" />
  <div v-else class="menu-container">
    <template v-if="isLanguageEditable">
      <KioskButton
        v-for="languageItem in languages"
        :key="languageItem.id"
        class="menu-button"
        color="primary"
        text-size="large"
        :disabled="maintenanceStore.loading"
        @click.stop="bannersViewController.startOrder(languageItem.id)"
      >
        {{ languageItem.label }}
      </KioskButton>
    </template>

    <template v-if="!isLanguageEditable && metadataStore.startButton">
      <KioskButton
        class="menu-button"
        color="primary"
        text-size="large"
        :disabled="maintenanceStore.loading"
        @click.stop="bannersViewController.startOrder()"
      >
        {{ $t('ORDER_HERE') }}
      </KioskButton>
    </template>
  </div>
</template>

<style scoped>
.menu-container {
  @apply flex gap-12 absolute bottom-56 w-full justify-center;
}

.menu-button {
  @apply min-w-[320px];
}
</style>
