/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createRootItem,
  customizable,
  ItemDescriptor,
  ModifierDescriptor } from '@slabcode/hubster-modifiers-utils';
import { ICustomizeItemState } from '../interfaces/customizeItemState';
import { GTMHistoryItem } from '@/common/interfaces/gtmHistoryItem';

const INITIAL_STATE: ICustomizeItemState = {
  item: null,
  upgradeModifier: null,
  modifierStack: [],
  isEditing: false,
  itemEditionIndex: null,
  history: [],
};

export const useCustomizeItemStore = defineStore('customizeItem', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    setupItem(itemId: string) {
      const root = createRootItem({ itemId });
      if (!customizable(root.actions)) return;
      root.actions.customize(({ itemClone, modifiers }) => {
        prepareModifiers({ modifiers });
        this.upgradeModifier = getUpgradeModifier(modifiers);
        this.validateModifier();
        listenChildItemsChanges({
          modifiers: itemClone.modifiers!,
          onChange: ({ item }) => this.pushHistoryItem(itemToHistoryItem(item)),
        });
        const defaultItems = getAutoSelectedModifiers(itemClone.modifiers!);
        defaultItems.forEach((item) =>
          this.pushHistoryItem(itemToHistoryItem(item)));
        this.item = itemClone;
      });
      this.isEditing = false;
    },

    setupItemEdition(item: ItemDescriptor) {
      if (!customizable(item.actions)) return;
      item.actions.customize(({ itemClone, modifiers, saveChanges }) => {
        const prevQuantity = itemClone.quantity;
        this.saveChanges = () => {
          itemClone.quantity = prevQuantity;
          saveChanges();
          this.isEditing = false;
        };
        itemClone.quantity = 1;
        this.upgradeModifier = getUpgradeModifier(modifiers);
        this.validateModifier();
        this.item = itemClone;
        listenChildItemsChanges({
          modifiers: itemClone.modifiers!,
          onChange: ({ item: eventItem }) =>
            this.pushHistoryItem(itemToHistoryItem(eventItem)),
        });
        const defaultItems = getAutoSelectedModifiers(itemClone.modifiers!);
        defaultItems.forEach((_item) =>
          this.pushHistoryItem(itemToHistoryItem(_item)));
        this.isEditing = true;
      });
    },

    pushModifier(item: ItemDescriptor) {
      if (!customizable(item.actions)) return;
      item.actions.customize(({ itemClone, modifiers, saveChanges }) => {
        prepareModifiers({ modifiers, autoSelect: false });
        this.modifierStack.push({ item: itemClone, saveChanges });
        listenChildItemsChanges({
          modifiers: itemClone.modifiers!,
          onChange: ({ item: eventItem }) =>
            this.pushHistoryItem(itemToHistoryItem(eventItem)),
        });
        const defaultItems = getAutoSelectedModifiers(itemClone.modifiers!);
        defaultItems.forEach((_item) =>
          this.pushHistoryItem(itemToHistoryItem(_item)));
      });
    },

    popModifier() {
      const modifier = this.modifierStack.pop();
      clearChildItemsListeners(modifier!.item.modifiers!);
    },

    popAndSaveModifier() {
      const modToSave = this.modifierStack.pop();
      modToSave?.saveChanges();
    },

    pushHistoryItem(item: GTMHistoryItem) {
      this.history.push(item);
    },

    finishCustomization() {
      clearChildItemsListeners(this.item!.modifiers!);
      this.item = null;
      this.upgradeModifier = null;
      this.modifierStack = [];
      while (this.history.pop()) {
        /* empty */
      }
    },

    validateModifier() {
      if (!this.upgradeModifier) return;

      if (this.upgradeModifier.items[0].itemMeta.status.saleStatus !== 'FOR_SALE') {
        this.upgradeModifier = null;
      }
    },
  },
  getters: {
    shouldHideDialog: (state) => (level: number) =>
      state.modifierStack[state.modifierStack.length - 1].item.level !== level,
    modifierSelections: () => (modifier: ModifierDescriptor) =>
      modifier.items.reduce((quantity, item) => item.quantity + quantity, 0),
  },
});
