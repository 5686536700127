import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { RouteName } from '../../../common/routes/routeName';

export function useFulfillmentController() {
  const router = useRouter();
  const settingsStore = useSettingsStore();
  const metadataStore = useMetadataStore();
  const orderTypeStore = useOrderTypeStore();

  /**
   * Determines whether to show the table view based on the current fulfillment mode and kiosk settings.
   * @returns {boolean} Returns true if the table view should be shown, false otherwise.
   */
  const showTableView = (): boolean => {
    const { fulfillmentMode } = orderTypeStore;
    const { kioskSettings } = metadataStore;

    if (!kioskSettings) {
      console.warn('there are not kiosk settings');
      return false;
    }

    const { dineIn, pickUp } = kioskSettings.fulfillment;

    if (fulfillmentMode === FulfillmentMode.DINE_IN && dineIn.settings.table) {
      return true;
    }

    if (fulfillmentMode === FulfillmentMode.PICKUP && pickUp.settings.table) {
      return true;
    }

    return false;
  };

  /*
  * Redirect when you are in order type view
  */
  const redirectFromFulfillment = (fulfillment: FulfillmentMode) => {
    orderTypeStore.fulfillmentMode = fulfillment;
    // if categories is disabled
    const redirectTo = metadataStore.hideCategories
      ? RouteName.PRODUCTS
      : RouteName.ORDER_CATEGORIES;

    router.push({ name: redirectTo });
  };

  function redirectFromMultibrand() {
    if (!metadataStore.kioskSettings?.fulfillment.dineIn.enabled || !metadataStore.kioskSettings?.fulfillment.pickUp.enabled) {
      const redirectTo = metadataStore.hideCategories
        ? RouteName.PRODUCTS
        : RouteName.ORDER_CATEGORIES;

      router.push({ name: redirectTo });

      if (metadataStore.kioskSettings?.fulfillment.dineIn.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.DINE_IN;
      }

      if (metadataStore.kioskSettings?.fulfillment.pickUp.enabled) {
        orderTypeStore.fulfillmentMode = FulfillmentMode.PICKUP;
      }

      return;
    }
    router.push({ name: RouteName.ORDER_TYPE });
  }

  function redirectToMultibrand() {
    let redirectTo = RouteName.PRODUCTS;

    if (metadataStore.kioskSettings?.fulfillment.dineIn.enabled && metadataStore.kioskSettings?.fulfillment.pickUp.enabled) {
      redirectTo = metadataStore.carouselImage ? RouteName.ORDER_TYPE : RouteName.FULFILLMENT;
    } else if (!metadataStore.hideCategories) {
      redirectTo = RouteName.ORDER_CATEGORIES;
    }

    router.push({ name: redirectTo });
  }

  /*
  * Redirect when you are in welcome view
  */
  const redirectFromWelcome = () => {
    const { kioskSettings, carouselImage } = metadataStore;
    if (!kioskSettings) {
      console.warn('there are not kiosk settings');
      return;
    }

    const { dineIn, pickUp } = kioskSettings.fulfillment;

    // Go to order type if both are enabled
    if (dineIn.enabled && pickUp.enabled) {
      router.push({ name: carouselImage ? RouteName.ORDER_TYPE : RouteName.FULFILLMENT });
      return;
    }

    // Assume one of them as unique answer and ignore order type screen
    if (dineIn.enabled) {
      redirectFromFulfillment(FulfillmentMode.DINE_IN);
    }

    if (pickUp.enabled) {
      redirectFromFulfillment(FulfillmentMode.PICKUP);
    }

    if (settingsStore.multibrandData?.multibrand) {
      redirectFromMultibrand();
    }
  };

  return { redirectFromFulfillment, redirectFromWelcome, showTableView, redirectToMultibrand };
}
