import { KioskMetadata } from '@/common/interfaces/checkUpdates';

export const useMetadataStore = defineStore('metadata', {
  state: (): KioskMetadata => ({
    kioskSettings: null,
    storeId: '',
    integration: '',
    banners: [],
    clusterSettings: null,
    customImages: null,
    brandSettings: null,
    pausedBanner: null,
    kioskSchedules: null,
  }),

  getters: {
    mainLogo: (state) => state.customImages?.brandImage ?? null,
    timelineImage: (state) => state.customImages?.timelineIcon ?? null,
    mainHeaderImage: (state) => state.customImages?.mainHeader ?? null,
    mainFooterImage: (state) => state.customImages?.mainFooter ?? null,
    modalHeaderImage: (state) => state.customImages?.modalHeader ?? null,
    modalFooterImage: (state) => state.customImages?.modalFooter ?? null,
    paymentImage: (state) => state.customImages?.paymentMethods ?? null,
    carouselImage: (state) => state.customImages?.carousel ?? null,
    loaderImage: (state) => state.customImages?.loader ?? null,
    startButton: (state) => state.kioskSettings?.options.startButton ?? true,
    hideCash: (state) =>
      !(state.kioskSettings?.payment.cash),
    hideCard: (state) =>
      !(state.kioskSettings?.payment.card),
    isCouponActionsEnabled: (state) => (state.kioskSettings?.coupons.enabled),
    hideCouponInput: (state) => state.kioskSettings?.coupons.settings.text,
    hideCouponQR: (state) => state.kioskSettings?.coupons.settings.qrScanner,
    colors: (state) => state.brandSettings?.colors ?? null,
    showPaginatedMG: (state) => state.brandSettings?.layout?.modifiers === 'steps',
    categoryColumns: (state) => state.brandSettings?.layout?.category ?? null,
    customFont: (state) => state.brandSettings?.fonts ?? null,
    priceDecimals: (state) => (state.clusterSettings?.currency.decimals ? 2 : 0),
    inactivity: (state) => state.brandSettings?.inactivity,
    showClientType: (state) => state.clusterSettings?.basics.customerInfo.showClientType,
    showName: (state) => state.clusterSettings?.basics.customerInfo.name,
    nestedModifiersStyle: (state) => state.brandSettings?.layout.nestedModifiers,
    counterStyle: (state) => state.brandSettings?.buttons.counterStyle,
    buttonsSettings: (state) => state.brandSettings?.buttons ?? null,
    cardsRadius: (state) => state.brandSettings?.cards.radius,
    isKioskDisabled: (state) => !!state.pausedBanner,
    cardRadiusClass: (state) => state.brandSettings?.cards.radius,
    legal: (state) => state.brandSettings?.legal ?? null,
    hideCategories: (state) => {
      if (!state.brandSettings) return false;
      return state.brandSettings.layout.category.toString() === CategoryLayouts.Disabled;
    },
    showCustomerView: (state) => Object.values(state.clusterSettings?.basics.customerInfo ?? {}).some((item) => item === true),
  },

  actions: {
    /**
     * Get updates
     */
    async getMetadata(): Promise<void> {
      const { clusterSettings, kioskSettings, banners, kioskSchedules } = await requests.get(`${this.storeId}/metadata`, {
        params: {
          timestamp: new Date().toLocaleString('sv'),
        },
      });

      this.clusterSettings = clusterSettings;
      this.kioskSettings = kioskSettings;
      this.banners = banners;
      this.kioskSchedules = kioskSchedules;
    },

    async getStaticData(storeId: string): Promise<void> {
      const { customImages, brandSettings } = await requests.get(
        `${storeId}/metadata/static`,
        { params: { integration: false } },
      );
      // Set request values
      this.$patch({ customImages, brandSettings });
    },

    setPausedBanner(bannerUrl: string): void {
      this.pausedBanner = bannerUrl;
    },
  },
});
