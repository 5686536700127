export function useKeyboardUtils() {
  const timeOut = ref<NodeJS.Timeout>();

  const keyboardDelete = (inputRef: HTMLInputElement, text: string): string => {
    clearTimeout(timeOut.value);
    const selectionStart = (inputRef.selectionStart ?? 0) - 1;
    if (selectionStart < 0) return text;
    const newText = text.slice(0, selectionStart) + text.slice(selectionStart + 1);
    timeOut.value = setTimeout(() => {
      if (!inputRef.value) return;
      inputRef.focus();
      inputRef.setSelectionRange(selectionStart, selectionStart);
    });

    return newText;
  };

  const keyboardAddLetter = (inputRef: HTMLInputElement, text: string, letter: string): string => {
    clearTimeout(timeOut.value);
    const selectionStart = inputRef.selectionStart ?? 0;
    const newText = text.slice(0, selectionStart) + letter + text.slice(selectionStart);
    timeOut.value = setTimeout(() => {
      if (!inputRef.value) return;
      inputRef.focus();
      inputRef.setSelectionRange(selectionStart + 1, selectionStart + 1);
    });

    return newText;
  };

  return {
    keyboardDelete,
    keyboardAddLetter,
  };
}
