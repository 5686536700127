<script setup lang="ts">
import { PropType } from 'vue';
import { useScroll } from '@vueuse/core';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { FadeColors } from '@/common/enums/fadeColors';
import { ProductsCategory } from '../interfaces';

const props = defineProps({
  selectedCategory: {
    type: Object as PropType<HubsterCategory>,
    required: false,
    default: () => ({}),
  },
  productCategories: {
    type: Array as PropType<ProductsCategory[]>,
    required: true,
  },
  isCartEmpty: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['updateCategory']);

const activeCategory = ref<number>(0);
const scrollingToActive = ref<boolean>(false);
const showAlcoholConfirm = ref<boolean>(false);
const container = ref<HTMLElement | null>(null);
const selectedItem = ref<HubsterMenuPublishItem>();
const categoryRefs = ref<(HTMLElement | null)[]>([]);

const route = useRoute();
const menuStore = useMenuStore();
const cartStoreV2 = useCartStoreV2();
const metadataStore = useMetadataStore();
const { alcohol } = storeToRefs(menuStore);
const { startCustomization } = useCustomize();
const { priceDecimals } = storeToRefs(metadataStore);
const { triggerSelectItem, triggerAddToCart } = useGTMEventsComposableV2();

// Monitor scroll position
const { y, arrivedState } = useScroll(container);

function addItem(item: HubsterMenuPublishItem) {
  triggerSelectItem({
    id: item.id,
    name: item.name,
    price: item.price.amount,
    quantity: 1,
  }, props.selectedCategory);

  if (item.modifierGroupIds.length === 0) {
    cartStoreV2.addItemWithoutCustomization(item.id);

    // Trigger GTM events
    triggerAddToCart({
      item: toGTMCartItem(cartStoreV2.items.at(-1)!),
      section: route.name,
      operation: CartEventType.ADD,
    });
    return;
  }

  startCustomization(item.id);
}

function setSelectedProduct(item: HubsterMenuPublishItem) {
  selectedItem.value = item;
}

function updateActiveCategory() {
  const scrollTop = y.value;
  // Buffer to ensure smooth category transitions
  const buffer = 200;

  categoryRefs.value.forEach((category, index) => {
    if (category) {
      const rect = category.getBoundingClientRect();
      const top = rect.top + scrollTop;
      const bottom = top + rect.height;

      if (scrollTop + buffer >= top && scrollTop + buffer < bottom) {
        if (scrollingToActive.value && index !== activeCategory.value) return;

        activeCategory.value = index;
        scrollingToActive.value = false;
        emit('updateCategory', props.productCategories[index].id);
      }
    }
  });
}

function denyAlcoholProducts() {
  selectedItem.value = undefined;
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ allow: false });
}

function confirmProductSelection() {
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ checked: true });
  // Add item to cart
  if (selectedItem.value) addItem(selectedItem.value);
}

function scrollToActiveCategory() {
// Make scroll
  categoryRefs.value[activeCategory.value]?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}

function openAlcoholConfirm(item: HubsterMenuPublishItem) {
  setSelectedProduct(item);

  if (alcohol.value.checked) {
    confirmProductSelection();
    return;
  }
  // Show alcohol confirm
  showAlcoholConfirm.value = true;
}

watch(y, () => updateActiveCategory(), { immediate: true });

watch(
  () => props.selectedCategory,
  (newCategory) => {
    const newIndex = props.productCategories.findIndex((c) => c.id === newCategory.id);
    if (newIndex === activeCategory.value) return;

    scrollingToActive.value = true;
    activeCategory.value = newIndex;
    scrollToActiveCategory();
  },
  { immediate: true },
);

onMounted(() => scrollToActiveCategory());
</script>

<template>
  <!-- TODO: Try with ScrollContainerV2 -->
  <div
    ref="container"
    class="scroll-container relative h-[1800px] mb-2"
  >
    <FadeScroll
      :color="FadeColors.BACKGROUND"
      position="top"
      :show="!arrivedState.top"
    />

    <div
      v-for="(category, index) in productCategories"
      :key="category.id"
      class="category"
      :ref="el => (categoryRefs[index] = el as HTMLElement)"
      :class="{ active: activeCategory === index }"
    >
      <div class="flex items-center gap-10 px-6">
        <div class="separator " />

        <h3 class="text-4xl leading-4 text-neutral-400">
          {{ category.name }}
        </h3>

        <div class="separator" />
      </div>

      <div
        class="grid content-start grid-cols-3 gap-6 p-6 justify-items-center"
        :key="category.id"
      >
        <CatalogItem
          v-for="product in category.items"
          :key="product.id"
          :product="product"
          :price-decimals="priceDecimals"
          :allow-alcohol="alcohol.allow"
          :class="['rounded-cards', metadataStore.cardRadiusClass]"
          @add-item="addItem(product)"
          @show-alcohol-confirm="openAlcoholConfirm(product)"
        />
      </div>
    </div>

    <FadeScroll
      :color="FadeColors.BACKGROUND"
      position="bottom"
      class="sticky"
      :show="!arrivedState.bottom"
    />
  </div>

  <AlcoholConfirm
    v-if="showAlcoholConfirm"
    @cancel="denyAlcoholProducts()"
    @confirm="confirmProductSelection()"
  />
</template>

<style scoped>
.scroll-container {
  @apply overflow-y-auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.category {
  @apply py-8;
}

.separator {
  @apply grow h-0.5 bg-neutral-400/50 my-4;
}

/* .category.active {
  border: 2px solid #007bff;
  background-color: #e9f7ff;
} */
</style>
