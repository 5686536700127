import { LanguageLocales } from '../../language/types';

export function useBannersViewController() {
  const maintenanceStore = useMaintenanceStore();
  const languageStore = useLanguageStore();
  const router = useRouter();
  const fulfillmentController = useFulfillmentController();

  async function startOrder(lang?: LanguageLocales) {
    const redirectTo = await maintenanceStore.getKioskStatus();

    if (lang) {
      languageStore.setLanguage(lang);
    }

    if (redirectTo) {
      router.push({ name: redirectTo });
      return;
    }

    fulfillmentController.redirectFromWelcome();
  }

  return { startOrder };
}
