<script lang="ts" setup>
import { useCustomize } from '../composables/useCustomize';
import { useItemActionEvent } from '../composables/useItemActionEvent';
import { FadeColors } from '@/common/enums/fadeColors';

const {
  item: currentItem,
  filteredModifiers,
  pushCustomization,
  saveCustomization,
  cancelCustomization,
  getModifierClass,
  checkUpgrade,
  acceptUpgrade,
  rejectUpgrade,
  shouldDisplayUpgrade,
  finishCustomization,
  filterItemsWithAlcohol,
  abortCustomization,
} = useCustomize();

const {
  selectItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  shouldDisplayAlcoholInput,
  enableAlcohol,
  disableAlcohol,
  showMaximumAnimation,
} = useItemActionEvent();

const menu = useMenuStore();
const { productsCurrency } = storeToRefs(menu);
const customizeItemStore = useCustomizeItemStore();
const { modifierStack, upgradeModifier, isEditing, item } = storeToRefs(customizeItemStore);
const metadataStore = useMetadataStore();
const { priceDecimals } = storeToRefs(metadataStore);
const stepIndex = ref(0);
const currentModifier = ref(filteredModifiers[stepIndex.value]);
const shouldDisplayExitConfirmation = ref<boolean>(false);
const { t } = useI18n();

const steps = computed(() => {
  const modifiersLength = filteredModifiers.length || 0;
  if (modifiersLength === 0) return [];

  return [...Array(modifiersLength)].map((_m, i) => ({
    label: (i + 1).toString(),
    value: i + 1,
  }));
});

const isLastStep = computed(
  (): boolean => stepIndex.value === (filteredModifiers.length || 1) - 1,
);

function formatPrice(total: number) {
  const price = formatCurrency(
    productsCurrency.value,
    total,
    priceDecimals.value,
  );

  if (!isLastStep.value) return `${t('NEXT')} | ${price}`;
  const result = isEditing.value ? t('SAVE') : t('ADD');

  return `${result} | ${price}`;
}

function submit({ add = true }: { add?: boolean } = {}) {
  if (add) {
    finishCustomization();
  } else {
    abortCustomization();
  }
}

function nextHandler(): void {
  if (!isLastStep.value) {
    currentModifier.value = filteredModifiers[(stepIndex.value += 1)];
    return;
  }

  if (checkUpgrade()) {
    return;
  }

  submit();
}

function prevHandler(): void {
  currentModifier.value = filteredModifiers[(stepIndex.value -= 1)];
}

function exit() {
  submit({ add: false });
}

function dismissUpgrade() {
  rejectUpgrade();
  submit();
}

function confirmUpgrade() {
  acceptUpgrade();
  submit();
}
</script>

<template>
  <section class="h-screen customization">
    <CustomizeItemHeader :item="currentItem!" />

    <div class="overflow-hidden customization-list">
      <KioskTimeline hide-labels :steps="steps" :current-index="stepIndex" />

      <ModifierCounter
        v-model="showMaximumAnimation"
        :modifier="currentModifier"
        :count="customizeItemStore.modifierSelections(currentModifier)"
      />

      <ScrollContainer
        :top="true"
        :bottom="true"
        tw-class="max-h-[1080px]"
        width="100%"
        height="auto"
        :color="FadeColors.WHITE"
      >
        <section ref="wrapper" class="modifier-container">
          <template
            v-for="itemMod in filterItemsWithAlcohol(currentModifier.items)"
            :key="itemMod.itemMeta.id"
          >
            <GridItem
              :item="itemMod"
              :container-class="getModifierClass(itemMod, true)"
              :modifier-decimals="priceDecimals"
              @select="selectItem"
              @customize="pushCustomization"
              @add="increaseItemQuantity($event)"
              @sub="decreaseItemQuantity($event)"
              :display-checkbox="
                currentModifier.dynamicMaxPerModifierSelectionQuantity === 1
                  && currentModifier.dynamicMaximumSelections > 1
              "
            />
          </template>
        </section>
      </ScrollContainer>
    </div>

    <div class="border-t border-gray-400">
      <CartActions
        :disabled="isLastStep ? !currentItem?.valid : !currentModifier?.valid"
        :action-lbl="formatPrice(item?.total ?? 0)"
        @next="nextHandler()"
        @back="shouldDisplayExitConfirmation = true"
      >
        <template #actions>
          <KioskButton
            @click="prevHandler()"
            :disabled="stepIndex === 0"
            class="flex gap-5 !text-3xl !py-3.5"
            text-size="medium"
            color="default"
          >
            {{ $t("BACK") }}
          </KioskButton>
        </template>
      </CartActions>
    </div>

    <!-- <OrderBottomActions
      back-icon=""
      :back-label="$t('BACK')"
      :next-label="formatPrice(item?.total ?? 0)"
      :disable-back="stepIndex === 0"
      :disable-next="isLastStep ? !currentItem?.valid : !currentModifier?.valid"
      @next-pressed="nextHandler"
      @back-pressed="prevHandler"
    >
      <template #prepend>
        <button
          type="button"
          class="w-1/5 h-20 text-4xl text-black uppercase btn btn-ghost"
          @click="shouldDisplayExitConfirmation = true"
        >
          <img src="/src/assets/svg/home.svg" alt="home" />
        </button>
      </template>
    </OrderBottomActions> -->

    <ConfirmModal
      v-if="shouldDisplayUpgrade && upgradeModifier"
      :icon="upgradeModifier.items[0].itemMeta.photoIds[0]"
      :title="upgradeModifier.items[0].itemMeta.name"
      :message="upgradeModifier.items[0].itemMeta.description"
      :local-modal="true"
      :success-button-label="$t('ORDER.ACCEPT_UPGRADE')"
      :deny-button-label="$t('ORDER.DENY_UPGRADE')"
      @on-close="dismissUpgrade"
      @on-ok="confirmUpgrade"
    />

    <ConfirmModal
      v-if="shouldDisplayExitConfirmation"
      :title="$t('MODIFIERS_BACK_TITLE')"
      :message="$t('MODIFIERS_BACK_DESCRIPTION')"
      :local-modal="true"
      :success-button-label="$t('TIMELINE.CONFIRM')"
      :deny-button-label="$t('CANCEL')"
      @on-close="shouldDisplayExitConfirmation = false"
      @on-ok="exit"
    />

    <ModifierDialog
      v-for="modElement in modifierStack"
      :key="modElement.item.itemMeta.id"
      :parent-item="modElement.item!"
      @save="saveCustomization"
      @cancel="cancelCustomization"
    />

    <AlcoholConfirm
      v-if="shouldDisplayAlcoholInput"
      modifiers
      @cancel="disableAlcohol"
      @confirm="enableAlcohol"
    />
  </section>
</template>

<style scoped>
.customization {
  @apply flex flex-col overflow-hidden;
}

.shake {
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.customization-button {
  @apply flex gap-1 items-center justify-center w-[308px] !h-16 py-3 text-3xl;
}

.modifier-container {
  @apply my-12 px-12 grid grid-cols-3 gap-8 place-content-start justify-items-center;
}

.optional-label {
  @apply text-neutral-400 bg-neutral-100 font-bold px-1 text-4xl rounded h-10 flex justify-center items-center;
}

.counter-span {
  @apply flex justify-center items-start w-20 h-10 leading-10 text-white border-none rounded px-1 font-bold;
}
</style>
