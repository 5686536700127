import { defineStore, acceptHMRUpdate } from 'pinia';
// import { IKioskStatus } from '@slabcode/kiosks-core/interfaces';
import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { LanguageLocales } from '@/modules/language/types';
import { MultibrandData } from '../interfaces';

// TODO: Refactor metadata & settings stores
export const useSettingsStore = defineStore('settings', () => {
  const route = useRoute();
  const gtmTransactionId = ref<string>('');
  const multibrandData = ref<MultibrandData>();
  const languageStore = useLanguageStore();
  const metadataStore = useMetadataStore();
  const orderTypeStore = useOrderTypeStore();
  const standbyBannerStore = useStandbyBannerStore();

  const { integration, storeId } = route.params;
  const { brandSettings, colors, customFont, kioskSettings, isKioskDisabled } = storeToRefs(metadataStore);

  function checkMultibrandData() {
    if ('multibrand' in route.query) {
      const data = route.query as unknown as MultibrandData;
      // Set values to store
      multibrandData.value = { ...data, multibrand: true };
    }
  }

  async function refreshMetadata() {
    await metadataStore.getMetadata();

    if (!kioskSettings.value) return;
    // Adapt enabled languages to language store
    const languages = kioskSettings.value.languages.enabled.map((lang) => ({
      id: lang.toLowerCase() as LanguageLocales,
      label: languagesLabels[lang],
    }));
    languageStore.setLanguages(languages);
    // Set default language
    languageStore.setLanguage(kioskSettings.value.languages.default?.toLowerCase() as LanguageLocales ?? 'es');
    languageStore.setEditableValue(kioskSettings.value.languages.enabled.length > 1);
    standbyBannerStore.setup();
  }

  async function loadStaticData() {
    const { customImages, brandSettings: settings } = await requests.get(
      `${metadataStore.storeId}/metadata/static`,
    );

    metadataStore.$patch({ customImages, brandSettings: settings });
  }

  function setupColorsConfig(): void {
    if (!colors.value) {
      // eslint-disable-next-line no-console
      console.warn('Colors config are not loaded at store');
      return;
    }

    Object.entries(colors.value).forEach(async ([name, color]) => {
      if (color.length) await generateCssRules(name, color);
    });
  }

  function setupRoot() {
    if (!brandSettings.value) return;
    generateRootCss(brandSettings.value);
  }

  function setupFontsConfig(): void {
    if (customFont.value) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = customFont.value.source;
      document.head.appendChild(link);
      // Set font to body
      document.body.style.fontFamily = customFont.value.name;
    }
  }

  async function fetchKioskMetadata() {
    await loadStaticData();
    setupRoot();
    setupColorsConfig();
    setupFontsConfig();

    // Setup order types
    orderTypeStore.setOrderOptions([
      { icon: 'eat-here', label: 'EAT_HERE', value: FulfillmentMode.DINE_IN },
      { icon: 'shopping-bag', label: 'TAKE_AWAY', value: FulfillmentMode.PICKUP },
    ]);
  }

  // On mounted
  metadataStore.$patch({
    integration: integration.toString(),
    storeId: storeId.toString(),
  });

  return {
    integration,
    storeId,
    isKioskDisabled,
    multibrandData,
    gtmTransactionId,
    refreshMetadata,
    fetchKioskMetadata,
    checkMultibrandData,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot));
}
