import { RouteRecordRaw } from 'vue-router';
import { RouteName } from '../../common/routes/routeName';

import OrdersWrapper from './OrdersWrapper.vue';
import OrderOptionsWrapper from '@/modules/orders/OrderOptionsWrapper.vue';
import OrderTypeView from '@/modules/order-type/views/OrderTypeView.vue';
import OrderCategoriesView from '@/modules/orders/views/OrderCategoriesView.vue';
import OrderProductsView from '@/modules/orders/views/OrderProductsView.vue';
import DropdownCustomizeItem from '@/modules/customize/views/DropdownCustomizeItem.vue';
import StepCustomizeItem from '@/modules/customize/views/StepCustomizeItem.vue';
import CartView from '@/modules/cart/views/CartView.vue';
import CheckoutView from '@/modules/checkout/views/CheckoutView.vue';
import VerticalStepCustomize from '../customize/views/VerticalStepCustomize.vue';

export const orderRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: RouteName.WRAPPER,
    component: OrdersWrapper,
    redirect: { name: RouteName.ORDER_OPTIONS },
    children: [
      {
        path: 'options',
        name: RouteName.ORDER_OPTIONS,
        component: OrderOptionsWrapper,
        redirect: { name: 'OrderTypeView' },
        children: [
          {
            path: 'type',
            name: RouteName.ORDER_TYPE,
            component: OrderTypeView,
          },
          {
            path: 'categories',
            name: RouteName.ORDER_CATEGORIES,
            component: OrderCategoriesView,
          },
        ],
      },
      {
        path: 'products',
        name: RouteName.PRODUCTS,
        component: OrderProductsView,
      },

      {
        path: 'customize',
        name: RouteName.DROPDOWN_CUSTOMIZE,
        component: DropdownCustomizeItem,
      },
      {
        path: 'customize',
        name: RouteName.STEP_CUSTOMIZE,
        component: StepCustomizeItem,
      },
      {
        path: 'customize',
        name: RouteName.VERTICAL_CUSTOMIZE,
        component: VerticalStepCustomize,
      },
      {
        path: 'cart',
        name: RouteName.CART,
        component: CartView,
      },
      {
        path: 'checkout',
        name: RouteName.CHECKOUT,
        component: CheckoutView,
      },
    ],
  },
];
