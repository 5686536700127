import getPalette from 'tailwindcss-palette-generator';
import { CssRule } from '@/common/interfaces';

const cssRules = {
  textStyle: (args: CssRule) => `.text-${args.name} { color: ${args.value} !important; }`,
  disabledTextStyle: (args: CssRule) => `disabled\\:text-${args.name} { color: ${args.value}; }`,
  backgroundStyle: (args: CssRule) => `.bg-${args.name} { background-color: ${args.value} !important; }`,
  backgroundStyleOpacity: (args: CssRule) => `.bg-${args.name}\\/40 { background-color: ${args.value}66 !important; }`,
  backgroundHoverStyle: (args: CssRule) => `.hover\\:bg-${args.name}:hover { background-color: ${args.value} !important; }`,
  backgroundBeforeStyle: (args: CssRule) => `.before\\:bg-${args.name}::before { background-color: ${args.value} !important; }`,
  backgroundAfterStyle: (args: CssRule) => `.after\\:bg-${args.name}::after { background-color: ${args.value} !important; }`,
  backgroundCheckedStyle: (args: CssRule) => `input:checked.checked\\:bg-${args.name} { background-color: ${args.value} !important; }`,
  borderStyle: (args: CssRule) => `.border-${args.name} { border-color: ${args.value} !important; }`,
  borderHoverStyle: (args: CssRule) => `.hover\\:bg-${args.name}:hover { border-color: ${args.value} !important; }`,
  borderBeforeStyle: (args: CssRule) => `.before\\:border-${args.name}::before { border-color: ${args.value} !important; }`,
  borderAfterStyle: (args: CssRule) => `.after\\:border-${args.name}::after { border-color: ${args.value} !important; }`,
  accentStyle: (args: CssRule) => `.accent-${args.name} { accent-color: ${args.value}; }`,
  fillStyle: (args: CssRule) => `.fill-${args.name} { fill: ${args.value}; }`,
  strokeStyle: (args: CssRule) => `.stroke-${args.name} { stroke: ${args.value}; }`,
  ringStyle: (args: CssRule) => `.ring-${args.name} { --tw-ring-color: ${args.value} !important; }`,
  ringBeforeStyle: (args: CssRule) => `.before\\:ring-${args.name}::before { --tw-ring-color: ${args.value} !important; }`,
  ringAfterStyle: (args: CssRule) => `.after\\:ring-${args.name}::after { --tw-ring-color: ${args.value} !important; }`,
  ringOffsetStyle: (args: CssRule) => `.ring-offset-${args.name} {
    --tw-ring-offset-color: ${args.value} !important;
    box-shadow: 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-shadow) !important;
  }`,
  toStyle: (args: CssRule) => `.to-${args.name} { --tw-gradient-to: ${args.value} var(--tw-gradient-to-position); }`,
  fromStyle: (args: CssRule) => `.from-${args.name} {
    --tw-gradient-from: ${args.value} var(--tw-gradient-from-position);
    --tw-gradient-to: ${args.value} var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }`,
};

export function generateCssRules(name: string, color: string): Promise<void> {
  // Generate palette
  const palette: Record<string, Record<string, string>> = getPalette({ color, name });
  // Add new style options
  const style = document.createElement('style');
  document.head.appendChild(style);
  const styleSheet = style.sheet!;

  return new Promise((resolve) => {
    // Loop through newColorPalette and generate styles dynamically
    Object.entries(palette[name]).forEach(([sheet, colorValue]) => {
      const className = `${name}-${sheet}`;
      // Add rules to styleSheet
      Object.values(cssRules).forEach((rule) => {
        const cssResult = rule({ name: className, value: colorValue });
        styleSheet.insertRule(cssResult, styleSheet.cssRules.length);
      });
    });
    // Resolve promise
    resolve();
  });
}
