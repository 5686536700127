/* eslint-disable no-use-before-define */
import { IKioskStatus } from '@slabcode/kiosks-core/interfaces';
import { watchPausable } from '@vueuse/core';
import { useTimeStore } from '../../../common/stores/time';
import { RouteName } from '../../../common/routes/routeName';

export const useMaintenanceStore = defineStore('maintenance', () => {
  const router = useRouter();
  const route = useRoute();
  const timeStore = useTimeStore();
  const metadataStore = useMetadataStore();

  const openStore = ref(true);
  const banner = ref<string | null>(null);
  const validateAgainCounter = ref(0);

  const loading = ref(false);

  const { now } = storeToRefs(timeStore);

  const { pause, resume } = watchPausable(now, () => {
    validateAgainCounter.value += 1;
    // Validate each 3 minutes if is open
    if (validateAgainCounter.value === 180) {
      validateAgainCounter.value = 0;
      getKioskStatus().then((redirectTo) => {
        if (redirectTo) {
          router.push({ name: redirectTo });
        }
      });
    }
  });

  const startChecking = () => {
    validateAgainCounter.value = 0;
    resume();
  };

  const stopChecking = () => {
    validateAgainCounter.value = 0;
    pause();
  };

  const checkStatus = (isOpen: boolean): string | null => {
    if (route.name === RouteName.MAINTENANCE && isOpen) {
      stopChecking();
      return RouteName.WELCOME;
    }

    if (!isOpen) {
      startChecking();
      return RouteName.MAINTENANCE;
    }

    return null;
  };

  const getKioskStatus = async () => {
    loading.value = true;
    try {
      const result: IKioskStatus = await requests.get(`/${metadataStore.storeId}/kioskStatus`);
      banner.value = result.status ? null : result.pausedBanner!;
      openStore.value = result.status;
      return checkStatus(result.status);
    } catch (error) {
      console.error(error);
      // Redirect to error screen
      banner.value = null;
      return null;
    } finally {
      loading.value = false;
    }
  };

  onMounted(() => {
    stopChecking();
  });

  return { getKioskStatus, loading, banner, openStore, validateAgainCounter };
});
