<script setup lang="ts">
const route = useRoute();
const cartUtils = useCartUtils();
const menuController = useMenuController();
const languageStore = useLanguageStore();
const inactiveStoreV2 = useInactiveStoreV2();
const settingsStore = useSettingsStore();

const { triggerStartOrder } = useGTMEventsComposableV2();
const { gtmTransactionId } = storeToRefs(settingsStore);

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  // Get menu
  const { storeId } = route.params;
  if (!storeId) return;
  await menuController.getMenu(storeId as string, languageStore.currentLanguage);

  // Generate transactionId
  gtmTransactionId.value = new Date().getTime().toString();
  triggerStartOrder();
}

function beforeUnmount() {
  cartUtils.restartCart();
  inactiveStoreV2.pauseChecking();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());

</script>

<template>
  <InactiveWrapper>
    <router-view />
  </InactiveWrapper>
</template>
