<!-- eslint-disable no-console -->
<script lang="ts" setup>
import { PropType } from 'vue';
import { Swiper as Slider, SwiperSlide } from 'swiper/vue';
import { Mousewheel, Navigation } from 'swiper/modules';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { ProductsCategory } from '../interfaces/productsCategory';
import noPhotoSrc from '@/assets/images/noPhoto.jpeg?url';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const props = defineProps({
  menuItems: {
    type: Array as PropType<ProductsCategory[]>,
    required: true,
  },
});

const emit = defineEmits<{
  goBack: [],
  selectCategory: [category: HubsterCategory],
}>();

const sliderEl = ref();
const menuStore = useMenuStore();
const metadataStore = useMetadataStore();
const { currentCategory } = storeToRefs(menuStore);
const { integration, mainLogo } = storeToRefs(metadataStore);

watch(
  currentCategory,
  (newValue) => {
    if (sliderEl.value && newValue) {
      const index = props.menuItems.findIndex((c) => c.id === newValue.id);
      const isVisible = sliderEl.value.visibleSlidesIndexes?.includes(index);
      if (!isVisible) sliderEl.value?.slideTo(index);
    }
  },
  { deep: true },
);

function goTo(next: boolean) {
  const index = props.menuItems.findIndex((c) => c.id === currentCategory.value?.id);
  const newIndex = next ? (index + 1) : (index - 1);
  emit('selectCategory', props.menuItems[newIndex]);
}
</script>

<template>
  <nav class="sidebar">
    <button
      type="button"
      class="flex items-center justify-center w-full h-56"
      @click="emit('goBack')"
    >
      <KioskImage
        v-if="mainLogo"
        :src="mainLogo"
        class="logo"
        image-class="max-h-52"
        hide-border
        :alt="`${integration} logo`"
      />
    </button>

    <div class="flex flex-col items-center justify-center slider-menu">
      <button
        type="button"
        class="nav-button prev-button-class"
      >
        <span class="text-5xl icon icon-arrow-up" />
      </button>

      <Slider
        v-if="menuItems.length > 0"
        ref="sliderEl"
        :direction="'vertical'"
        :slides-per-view="7"
        :touch-ratio="0.5"
        :autoplay="false"
        :modules="[Navigation, Mousewheel]"
        :navigation="{
          enabled: true,
          prevEl: '.prev-button-class',
          nextEl: '.next-button-class',
        }"
        watch-slides-progress
        touch-start-prevent-default
        class="w-full h-[1568px] border-b"
        @swiper="sliderEl = $event"
        @navigation-prev="goTo(false)"
        @navigation-next="goTo(true)"
      >
        <SwiperSlide
          v-for="item in menuItems"
          :key="item.id"
          class="menu-item-button"
          :class="{ 'bg-primary-500/40 border-primary-500 !border-t-0 !text-neutral-600': currentCategory?.id === item.id }"
          @click="emit('selectCategory', item)"
        >
          <KioskImage
            image-class="object-cover object-center w-32 h-32 rounded-md"
            :src="item.description.length ? item.description : noPhotoSrc"
            :alt="item.name"
            hide-border
          />
          <span class="flex items-center mt-2 text-2xl leading-none text-center first-capitalize min-h-12">
            {{ item.name }}
          </span>
        </SwiperSlide>
      </Slider>

      <button
        type="button"
        class="nav-button next-button-class"
      >
        <span class="text-5xl icon icon-arrow-down" />
      </button>
    </div>
  </nav>
</template>

<style scoped>
.logo {
  @apply object-cover;
}

.menu-item-button {
  @apply w-full h-full flex flex-col font-light text-neutral-400 items-center justify-center p-3 border-r-4 border-r-transparent border-t border-b-neutral-200;
}

.top-animation {
  @apply animate-bounce;
}

.sidebar {
  @apply fixed top-0 justify-start items-center bg-white z-20 h-full w-56;
}

.nav-button {
  @apply h-16 disabled:opacity-50;
}
</style>
