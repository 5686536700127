<script setup lang="ts">
defineProps({
  backLabel: {
    type: String,
    required: true,
  },
  backIcon: {
    type: String,
    required: false,
    default: 'redo',
  },
  disableBack: {
    type: Boolean,
    required: false,
    default: false,
  },
  nextLabel: {
    type: String,
    required: false,
    default: '',
  },
  disableNext: {
    type: Boolean,
    required: false,
    default: false,
  },
  showNextButton: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(['backPressed', 'nextPressed', 'catalogPressed']);
</script>

<template>
  <div class="z-20 border-t shadow-lg kiosk-footer border-neutral-200">
    <slot name="prepend" />

    <div class="flex items-center justify-end w-2/3 gap-10 actions">
      <!-- @vue-ignore-->
      <KioskButton
        :icon="backIcon"
        text-size="medium"
        :disabled="disableBack"
        :class="{ '!w-auto': !showNextButton }"
        @click="emit('backPressed')"
      >
        {{ backLabel }}
      </KioskButton>

      <KioskButton
        v-if="showNextButton"
        :disabled="disableNext"
        color="primary"
        text-size="medium"
        @click="emit('nextPressed')"
      >
        {{ nextLabel }}
      </KioskButton>
    </div>
  </div>
</template>

<style scoped>
.actions button {
  @apply !py-3.5 max-w-[444px] text-3xl;
}
</style>
